// for partners
const swiper = new Swiper('.partners-slider', {
  loop: true,
  slidesPerView: 1,
  spaceBetween: 20,
  centeredSlides: true,
  autoplay: {
    delay: 5000,
  },

  navigation: {
    nextEl: '.partners-slider-button-next',
    prevEl: '.partners-slider-button-prev',
  },

  breakpoints: {
    768: {
      slidesPerView: 3,
    },
    1200: {
      slidesPerView: 5,
    }
  }
});
