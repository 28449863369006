// for header/nav-block
$(function() {
  $('.nav-block__btn').click(function() {
    $('.dropdown').removeClass('active');
    $('.nav-block').toggleClass('active');
  });
});

$(function() {
  $('.menu__item .menu__link').click(function() {
    $('.dropdown--mobile>a').parent().removeClass('active');
    $(this).parent().toggleClass('active').siblings().removeClass('active');
  });
});

$(function() {
  $('.dropdown--mobile>a').click(function(e) {
    e.preventDefault();
    $(this).parent().toggleClass('active');
  });
});

// for header/mobile-menu
$(function() {
  $('.product__toggle').click(function() {
    $('.product__menu .dropdown').removeClass('active');
    $('.product__menu').toggleClass('active');
  });
});

$(function() {
  $('.product__menu .dropdown>a').click(function() {
    $(this).parent().toggleClass('active');
  });
});

// for modal
$(function() {
  $('.order-btn').click(function() {
    $('.modal--order').addClass('modal--active');
    $('.modal-overlay').addClass('modal-overlay--active');
  });
});

$(function() {
  $('.sample-btn').click(function() {
    $('.modal--sample').addClass('modal--active');
    $('.modal-overlay').addClass('modal-overlay--active');
  });
});

$(function() {
  $('.calc-btn').click(function() {
    $('.modal--calc').addClass('modal--active');
    $('.modal-overlay').addClass('modal-overlay--active');
  });
});

$(function() {
  $('.close-btn').click(function() {
    $('.modal').removeClass('modal--active');
    $('.modal-overlay').removeClass('modal-overlay--active');
  });
});

$(function() {
  $('.modal-overlay').click(function() {
    $('.modal').removeClass('modal--active');
    $(this).removeClass('modal-overlay--active');
  });
});
